import {
  GET_MAM_PROFILES_START,
  GET_MAM_PROFILES_FAIL,
  GET_MAM_PROFILES_SUCCESS,
  GET_MAM_PROFILE_START,
  GET_MAM_PROFILE_FAIL,
  GET_MAM_PROFILE_SUCCESS,
  SET_SELECTED_MAM_PROFILE,
} from "./actionTypes";

const initialState = {
  load: false,
  profiles: [],
  profile: {
    loading: false,
  },
  selectedMamAccount: null,
};

function mamProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_MAM_PROFILE:
      return {
        ...state,
        selectedMamAccount: action.payload,
      };
    case GET_MAM_PROFILES_START:
      return {
        ...state,
        profile: { loading: true },
      };
    case GET_MAM_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: [...action.payload.docs],
        profilesPagination: {
          hasNextPage: action.payload.hasNextPage,
          nextPage: action.payload.nextPage,
          limit: action.payload.limit,
          totalDocs: action.payload.totalDocs,
          totalPages: action.payload.totalPages,
          page: action.payload.page,
          pagingCounter: action.payload.pagingCounter,
          hasPrevPage: action.payload.hasPrevPage,
          prevPage: action.payload.prevPage,
        },
      };
    case GET_MAM_PROFILES_FAIL:
      return {
        ...state,
        profile: { loading: false },
      };
    case GET_MAM_PROFILE_START:
      return {
        ...state,
        profile: { loading: true },
      };
    case GET_MAM_PROFILE_SUCCESS:
      return {
        ...state,
        profile: { loading: false },
      };
    case GET_MAM_PROFILE_FAIL:
      return {
        ...state,
        profile: { loading: false },
      };
    default:
      return state;
  }
}

export default mamProfileReducer;
