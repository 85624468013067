import {
  REQ_IB_START,
  REQ_IB_SUCCESS,
  REQ_IB_FAIL,
  GET_IB_REQUEST_STATUS_SUCCESS,
  GET_IB_REQUEST_STATUS,
  GET_IB_REQUEST_STATUS_FAIL,
  CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
  CREATE_CHANGE_LEVERAGE_REQ_SUCCESS,
  CREATE_CHANGE_LEVERAGE_REQ_FAIL,
  POST_UNLINK_REQ_REQUESTED,
  POST_UNLINK_REQ_SUCCESS,
  POST_UNLINK_REQ_FAIL,
  POST_LINK_REQ_FAIL,
  POST_LINK_REQ_SUCCESS,
  POST_LINK_REQ_REQUESTED,
  REQ_INVESTOR_START,
  REQ_INVESTOR_SUCCESS,
  REQ_INVESTOR_FAIL,
  GET_INVESTOR_REQUEST_STATUS_FAIL,
  GET_INVESTOR_REQUEST_STATUS_SUCCESS,
  GET_INVESTOR_REQUEST_STATUS,
  REQ_MASTER_START,
  REQ_MASTER_SUCCESS,
  REQ_MASTER_FAIL,
  GET_MASTER_REQUEST_STATUS,
  GET_MASTER_REQUEST_STATUS_SUCCESS,
  GET_MASTER_REQUEST_STATUS_FAIL,
} from "./actionTypes";

const initialState = {
  partnership: {
    loading: false,
    getStatusLoader: false,
  },
  unlinkAccount: {
    loading: false,
    isOPenModal: true,
  },
  linkAccount: {
    loading: false,
    isOPenModal: true,
  },
  investor: {
    loading: false,
    getStatusLoader: false,
  },
  master: {
    loading: false,
    getStatusLoader: false,
  },
};

function requests(state = initialState, action) {
  switch (action.type) {
    case REQ_IB_START:
      return {
        ...state,
        partnership: { loading: true },
      };
    case REQ_IB_SUCCESS:
      return {
        ...state,
        partnership: {
          loading: false,
          result: action.payload,
        },
      };
    case REQ_IB_FAIL:
      return {
        ...state,
        partnership: {
          loading: false,
        },
      };
    case GET_IB_REQUEST_STATUS:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          getStatusLoader: true,
        },
      };
    case GET_IB_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          getStatusLoader: false,
          status: action.payload.status,
        },
      };
    case GET_IB_REQUEST_STATUS_FAIL:
      return {
        ...state,
        partnership: {
          ...state.partnership,
          getStatusLoader: false,
        },
      };

    // change leverage request
    case CREATE_CHANGE_LEVERAGE_REQ_REQUESTED:
      return {
        ...state,
        createChangeLeverageRequestLoading: true,
      };
    case CREATE_CHANGE_LEVERAGE_REQ_SUCCESS:
      return {
        ...state,
        createChangeLeverageRequestLoading: false,
        createChangeLeverageRequestFail: false,
      };
    case CREATE_CHANGE_LEVERAGE_REQ_FAIL:
      return {
        ...state,
        createChangeLeverageRequestLoading: false,
        createChangeLeverageRequestFail: true,
      };

    // unlink account request
    case POST_UNLINK_REQ_REQUESTED:
      return {
        ...state,
        unlinkAccount: {
          ...state.unlinkAccount,
          loading: true,
        },
      };
    case POST_UNLINK_REQ_SUCCESS:
      return {
        ...state,
        unlinkAccount: {
          ...state.unlinkAccount,
          loading: false,
        },
      };
    case POST_UNLINK_REQ_FAIL:
      return {
        ...state,
        unlinkAccount: {
          ...state.unlinkAccount,
          loading: false,
        },
      };

    // link account request
    case POST_LINK_REQ_REQUESTED:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          loading: true,
        },
      };
    case POST_LINK_REQ_SUCCESS:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          loading: false,
          isOPenModal: false,
        },
      };
    case POST_LINK_REQ_FAIL:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          loading: false,
        },
      };

    case REQ_INVESTOR_START:
      return {
        ...state,
        investor: { loading: true },
      };
    case REQ_INVESTOR_SUCCESS:
      return {
        ...state,
        investor: {
          loading: false,
          result: action.payload,
        },
      };
    case REQ_INVESTOR_FAIL:
      return {
        ...state,
        investor: { loading: false },
      };

    case GET_INVESTOR_REQUEST_STATUS:
      return {
        ...state,
        investor: {
          ...state.investor,
          getStatusLoader: true,
        },
      };
    case GET_INVESTOR_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        investor: {
          ...state.investor,
          getStatusLoader: false,
          status: action.payload.status,
        },
      };
    case GET_INVESTOR_REQUEST_STATUS_FAIL:
      return {
        ...state,
        investor: {
          ...state.investor,
          getStatusLoader: false,
        },
      };

    case REQ_MASTER_START:
      return {
        ...state,
        master: { loading: true },
      };
    case REQ_MASTER_SUCCESS:
      return {
        ...state,
        master: {
          loading: false,
          result: action.payload,
        },
      };
    case REQ_MASTER_FAIL:
      return {
        ...state,
        master: { loading: false },
      };

    case GET_MASTER_REQUEST_STATUS:
      return {
        ...state,
        master: {
          ...state.master,
          getStatusLoader: true,
        },
      };
    case GET_MASTER_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        master: {
          ...state.master,
          getStatusLoader: false,
          status: action.payload.status,
        },
      };
    case GET_MASTER_REQUEST_STATUS_FAIL:
      return {
        ...state,
        master: {
          ...state.master,
          getStatusLoader: false,
        },
      };

    default:
      return state;
  }
}
export default requests;
