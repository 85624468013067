import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CardBody } from "reactstrap";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CardWrapper from "components/Common/CardWrapper";
import { showSuccessNotification } from "store/actions";
import dedicatedLinks from "./spLinks";

const SpDedicatedLinks = ({ agRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CardWrapper className="dedicated-links mt-4 glass-card shadow">
      <CardBody>
        {!agRef ? (
          <div className="d-flex justify-content-center">{t("Select Account")}</div>
        ) : (
          <Table className="table table-hover table-borderless">
            <Thead>
              <Tr>
                <Th className="py-0">{t("Type")}</Th>
                <Th className="py-0">{t("Link")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dedicatedLinks.map((link, idx) => (
                <Tr key={idx}>
                  <Td className="py-0">{t(link.type)}</Td>
                  <Td className="py-0">
                    <Link
                      to="#"
                      className="mdi mdi-clipboard-check-multiple-outline font-size-20 me-2"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${link.url}?${!link.url.includes("ib") ? `&spRef=${agRef}` : ""}`
                        );
                        dispatch(showSuccessNotification("Link copied to clipboard"));
                      }}
                    ></Link>
                    {`${link.url}?${!link.url.includes("ib") ? `&spRef=${agRef}` : ""}`}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </CardBody>
    </CardWrapper>
  );
};

export default SpDedicatedLinks;
