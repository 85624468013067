import { JClickHandler } from "components/Journey/handlers";
// eslint-disable-next-line object-curly-newline
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { toggleCurrentModal } from "store/actions";

const InvestorJourney = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [stages, setStages] = useState({
    kycApproved: false,
    kycUpload: false,
    kycRejected: false,
    startTrading: false,
    investor: {
      becomeInvestor: false,
      openAccount: false,
      linkAccount: false,
      requestLink: false,
    },
    individual: { submitProfile: false },
    loaded: false,
  });

  useEffect(() => {
    if (props.stages) {
      setStages({
        ...stages,
        loaded: true,
        kycUpload: props.stages.kycUpload,
        kycApproved: props.stages.kycApproved,
        kycRejected: props.stages.kycRejected,
        startTrading: props.stages.startTrading,
        madeDeposit: props.stages?.madeDeposit,
        investor: {
          ...props.stages.investor,
          becomeInvestor: props.stages.investor && props.stages.investor.becomeInvestor,
          openAccount: props.stages.investor && props.stages.investor.openAccount,
          linkAccount: props.stages.investor && props.stages.investor.linkAccount,
          requestLink: props.stages.investor && props.stages.investor.requestLink,
        },
        individual: {
          ...props.stages.individual,
          submitProfile: props.stages.individual && props.stages.individual.submitProfile,
        },
      });
    }
  }, [props.stages]);

  useEffect(() => {
    if (props.clientData) {
      if (props.stages && stages.loaded) {
        if (!stages.individual.submitProfile) {
          JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.investor.openAccount) {
          JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.kycUpload) {
          JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.madeDeposit || !stages.investor.linkAccount) {
          JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)();
        }
      }
    }
  }, [stages, props.stages]);

  if (
    stages.individual.submitProfile &&
    stages.investor.linkAccount &&
    stages.kycApproved &&
    stages.investor.openAccount &&
    stages.madeDeposit
  ) {
    return <></>;
  }

  return (
    <div className="mb-5">
      {props.clientData && !props.clientData.isLead && (
        <Fragment>
          <div className="steps mb-5">
            <div
              className={
                stages.individual.submitProfile
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">1</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Sign up")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.investor.openAccount
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">2</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Open Investor Account")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.kycApproved
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">3</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Verify")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.madeDeposit
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">4</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Deposit Funds")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.investor.linkAccount
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("investorJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">5</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Link Account")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stages: (state.Profile.clientData && state.Profile.clientData.stages) || {},
  clientData: state.Profile.clientData || {},
});
export default connect(mapStateToProps, null)(InvestorJourney);
