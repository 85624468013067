const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const layoutTheme = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
};

const layoutPositions = {
  SCROLLABLE_TRUE: "true",
  SCROLLABLE_FALSE: "false",
};

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

const leftSidebarTypes = {
  DEFAULT: "lg",
  COMPACT: "md",
  ICON: "sm",
};

const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "brand",
  DARK: "dark",
};

export {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
};
