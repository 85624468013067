import { getProfitGraphAPI } from "apis/forex/accounts";
import classNames from "classnames";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Button } from "reactstrap";

export default function ProfitGraph({ accountId }) {
  const [loading, setLoading] = React.useState(false);
  const [profitGraph, setProfitGraph] = React.useState([]);
  const [type, setType] = React.useState("daily");
  const setFakeData = (type) => {
    const newProfitGraph = [];
    if (type === "daily") {
      for (let i = 0; i < 7; i++) {
        newProfitGraph.push({
          x: new Date(2023, 8, i),
          y: Math.floor(Math.random() * 1000),
        });
      }
    }
    if (type === "monthly") {
      for (let i = 0; i < 12; i++) {
        newProfitGraph.push({
          x: new Date(2023, i, 1),
          y: Math.floor(Math.random() * 1000),
        });
      }
    }
    if (type === "yearly") {
      for (let i = 0; i < 5; i++) {
        newProfitGraph.push({
          x: new Date(2018 + i, 1, 1),
          y: Math.floor(Math.random() * 1000),
        });
      }
    }
    setProfitGraph(newProfitGraph);
    setLoading(false);
  };
  const handleGraphChange = () => {
    setLoading(true);
    // setFakeData(type);
    getProfitGraphAPI({
      accountId,
      type,
    })
      .then((res) => {
        if (res.result) {
          const newProfitGraph = res.result?.map((item) => {
            if (type === "daily")
              return {
                x: new Date(item.date),
                y: item.profit,
              };
            if (type === "monthly")
              return {
                x: item.date.split("-").slice(0, 2).join("-"),
                y: item.profit,
              };
            if (type === "yearly")
              return {
                x: item.date.split("-")[0],
                y: item.profit,
              };
          });
          setProfitGraph(newProfitGraph);
        } else {
          setProfitGraph([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setProfitGraph([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGraphChange();
  }, [type, accountId]);

  return (
    <div className="my-3 mx-2 p-3">
      {/* 3 button group for daily, monthly, yearly */}
      <div className="d-flex justify-content-between">
        <div className="btn-group" role="group" aria-label="Basic example">
          <Button
            type="button"
            className={classNames("btn btn-light border-0 ", {
              "shadow-lg text-white color-bg-btn": type === "daily",
            })}
            onClick={() => {
              setType("daily");
            }}
          >
            Daily
          </Button>
          <Button
            type="button"
            className={classNames("btn btn-light border-0 ", {
              "shadow-lg text-white color-bg-btn": type === "monthly",
            })}
            onClick={() => {
              setType("monthly");
            }}
          >
            Monthly
          </Button>
          <Button
            type="button"
            className={classNames("btn btn-light border-0 ", {
              "shadow-lg text-white color-bg-btn": type === "yearly",
            })}
            onClick={() => {
              setType("yearly");
            }}
          >
            {" "}
            Yearly
          </Button>
        </div>
      </div>
      <div className="w-100 text-center">
        {loading ? (
          <div className="spinner-border" role="status"></div>
        ) : (
          <ReactApexChart
            options={{
              chart: {
                type: "area",
                height: 350,
                zoom: {
                  enabled: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
              },
              title: {
                text: "Profit Analysis",
                align: "left",
              },
              // labels: [],
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                opposite: true,
              },
              legend: {
                horizontalAlign: "left",
              },
            }}
            series={[
              {
                name: "Profit",
                data: profitGraph,
              },
            ]}
            type="area"
            height={350}
          />
        )}
      </div>
    </div>
  );
}
