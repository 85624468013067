import {
  GET_CP_FEETRADING_WITHDRAWALS_START,
  GET_CP_INVESTOR_DEPOSITS_START,
  GET_CP_INVESTOR_WITHDRAWALS_START,
  GET_CP_MASTER_DEPOSITS_START,
} from "./actionTypes";

export const getCpTransactions = (params, variant) => {
  if (variant === "MasterDeposit")
    return {
      type: GET_CP_MASTER_DEPOSITS_START,
      payload: {
        params,
        variant,
      },
    };
  if (variant === "InvestorDeposit") {
    return {
      type: GET_CP_INVESTOR_DEPOSITS_START,
      payload: {
        params,
        variant,
      },
    };
  }
  if (variant === "InvestorWithdraw")
    return {
      type: GET_CP_INVESTOR_WITHDRAWALS_START,
      payload: {
        params,
        variant,
      },
    };
  if (variant === "FeeTradingWithdraw")
    return {
      type: GET_CP_FEETRADING_WITHDRAWALS_START,
      payload: {
        params,
        variant,
      },
    };
};
