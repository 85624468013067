import React from "react";
import OwlCarousel from "react-owl-carousel";
// import { Col } from "reactstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withTranslation } from "react-i18next";
import s1 from "assets/slider/slider1.png";
import s2 from "assets/slider/slider2.png";
import s3 from "assets/slider/slider3.png";
import s4 from "assets/slider/slider4.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Slider = (props) => {
  const history = useHistory();
  return (
    <div className="slider slider_home mb-3">
      <div className="slider__container">
        <OwlCarousel style={{ direction: "ltr" }} className="owl-theme" loop items={1}>
          <div
            className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className="">
              <img
                style={{
                  cursor: "pointer",
                }}
                src={s1}
                alt=""
                onClick={() => {
                  history.push("/request-investor");
                }}
              />
            </div>
          </div>
          <div
            className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className="">
              <img
                style={{
                  cursor: "pointer",
                }}
                src={s2}
                alt=""
                onClick={() => {
                  history.push("/request-master");
                }}
              />
            </div>
          </div>
          <div
            className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className="">
              <img
                style={{
                  cursor: "pointer",
                }}
                src={s3}
                alt=""
                onClick={() => {
                  history.push("/request-investor");
                }}
              />
            </div>
          </div>
          <div
            className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className="">
              <img
                style={{
                  cursor: "pointer",
                }}
                src={s4}
                alt=""
                onClick={() => {
                  history.push("/request-master");
                }}
              />
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};
export default withTranslation()(Slider);
