import React, { Fragment } from "react";
// eslint-disable-next-line object-curly-newline
import { CardTitle, Modal, Button, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProfileRequest = (props) => {
  const { t } = useTranslation();
  const { isOpen: show, toggle } = props;
  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered={true}
        //   size="lg"
        className="custom-modal"
      >
        <ModalHeader className="d-flex flex-column gap-3">
          <CloseButton
            onClick={() => toggle()}
            style={{
              alignSelf: "flex-end",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          />
          <div className="text-center">
            <CardTitle className="mb-0">{t("Start Trading")}</CardTitle>
          </div>
        </ModalHeader>
        <ModalBody>
          <h4 className="m-5 text-center">{t("Please wait until your request is approved")}</h4>
          <div className="text-center">
            <Button
              onClick={() => {
                toggle();
              }}
              color="success"
              className="w-lg waves-effect waves-light m-2"
            >
              {t("I understand")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ProfileRequest;
