import FeesTable from "pages/Forex/Dashboard/InvestorPortal/FeesTable";
import { useTranslation } from "react-i18next";

const FeesNotCollected = ({ selectedProfile }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="color-primary">{t("Fees Not Collected")}</h1>
      <FeesTable selectedProfile={selectedProfile} />
    </>
  );
};
export default FeesNotCollected;
