import * as axiosHelper from "./api_helper";

export const getWallets = async () => {
  const data = await axiosHelper.get("/wallets");
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data?.result;
};
export const addWithdraw = async (values) => {
  // const { id, values } = payload;
  const data = await axiosHelper.post("transactions/withdraw", values);
  // if (data.isError) {
  //   throw new Error(data.message);
  // }
  return data;
};
export const addWithdrawCrypto = async (values) => {
  // const { id, values } = payload;
  const data = await axiosHelper.post("transactions/withdraw/crypto", values);
  // if (data.isError) {
  //   throw new Error(data.message);
  // }
  return data;
};
export const addFxWithdraw = async (values, selectedAccount) => {
  if (values instanceof FormData && selectedAccount[0]?.currency === "CENT") {
    let amount = parseFloat(values.get("amount")) / 100;
    values.set("amount", amount);
    values.set("currency", "CENT");
  } else if (selectedAccount[0]?.currency === "CENT") {
    values.amount = values.amount / 100;
    values.currency = "CENT";
  }
  const data = await axiosHelper.post("fxtransactions/withdrawals", values);
  return data;
};
// export const fetchwallets = async () => {
//     const data = await axiosHelper.get('wallets');
//     return data.result;
// }
