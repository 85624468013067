import React, { useEffect, useState } from "react";
import { Alert, Input, InputGroup, Label } from "reactstrap";
import { default as AvFieldSelect } from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import { getAccountsStart } from "store/actions";
export default function Mt5Details(props) {
  const {
    t,
    amount,
    setAmount,
    amountValidation,
    amountError,
    setAmountValidation,
    selectedAccount,
    setSelectedAccount,
    minDepositAmount,
  } = props;
  const dispatch = useDispatch();
  const { accounts, loading } = useSelector((state) => state.forex.accounts);

  useEffect(() => {
    dispatch(getAccountsStart({}));
  }, []);

  const handleAccountChange = (event) => {
    setSelectedAccount(accounts.filter((acc) => acc._id === event));
  };

  const amountValidationHanlder = (e) => {
    e.target.value < minDepositAmount ? setAmountValidation(true) : setAmountValidation(false);
  };

  useEffect(() => {
    if (selectedAccount[0]?.currency === "CENT") {
      setAmountValidation(false);
    } else if (selectedAccount[0]?.currency !== "CENT" && amount < minDepositAmount) {
      setAmountValidation(true);
    }
  }, [selectedAccount, amount]);

  return (
    <div className="w-100">
      {loading ? (
        <Loader />
      ) : (
        accounts && (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <Label htmlFor="example-date-input" className="form-label">
                  {props.t("MT5 Account")}
                </Label>
              </div>
              <div>
                {props.t("Transaction requirements")}
                <i className="fas fa-info-circle ms-2"></i>
              </div>
            </div>
            <div>
              <AvFieldSelect
                options={accounts
                  ?.filter((a) => !["DEMO", "MAM_FEE", "IB"].includes(a.type))
                  ?.filter((a) => (a.profile ? a.profile?.profileType === 9 : true))
                  .map((acc) => {
                    return {
                      label: `${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc?.Balance} ${acc.currency})`,
                      value: acc._id,
                    };
                  })}
                onChange={(event) => handleAccountChange(event)}
                name="tradingAccountId"
                type="text"
                errorMessage={t("MT5 account is required")}
                validate={{ required: { value: true } }}
                // label={t("MT5 Account")}
              ></AvFieldSelect>
            </div>
            {amountError && <p className="small text-danger "> {t("Please Select Account")}</p>}
            {selectedAccount && (
              <div className="mt-3">
                <Label htmlFor="example-date-input" className="form-label">
                  {props.t(`Amount (${selectedAccount[0]?.currency})`)}
                </Label>
                <InputGroup>
                  <Input
                    required
                    onChange={(e) => {
                      amountValidationHanlder(e);
                      setAmount(e.target.value);
                    }}
                    className="form-control"
                    type="number"
                    min="0"
                    value={amount}
                    placeholder="Enter 15-128341"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
                {selectedAccount?.[0]?.currency === "CENT" && (
                  <div className="mt-3">
                    <Alert color="warning">
                      {t("Conversion rate will be applied.")}
                      {" 1 CENT = 0.01 USD"}
                    </Alert>
                  </div>
                )}

                {amountValidation && (
                  <p className="small text-danger ">
                    {" "}
                    {t("The minimum value for amount is " + minDepositAmount)}
                  </p>
                )}
              </div>
            )}
          </>
        )
      )}
    </div>
  );
}
