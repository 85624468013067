import { combineReducers } from "redux";

import accounts from "./accounts/reducer";
import ForexLayout from "./ForexLayout/reducer";
import requests from "./requests/reducer";
import ibReducer from "./ib/reducers";
import mamProfileReducer from "./mamProfile/reducer";
import cpReducer from "./cp/reducers";

const forexReducer = combineReducers({
  accounts,
  ForexLayout,
  requests,
  ib: ibReducer,
  cp: cpReducer,
  mamProfile: mamProfileReducer,
});

export default forexReducer;
