import { all, fork } from "redux-saga/effects";
import transactionsSaga from "./transactions/saga";

export default function* cpSaga() {
  yield all([
    // fork(clientsSaga),
    fork(transactionsSaga),
    // fork(agreementSaga),
  ]);
}
