/* eslint-disable indent */
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import Select from "react-select";
import PageHeader from "components/Forex/Common/PageHeader";
import SpDedicatedLinks from "./SpDedicatedLinks";
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from "react";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";
import CustomSelect from "components/Common/CustomSelect";

const MyDedicatedLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.forex.mamProfile);
  const [selectedAccount, setSelectedAccount] = useState();

  const selectedRecordId = useMemo(() => {
    if (selectedAccount) {
      const filteredAccount = profiles.filter(
        (obj) => obj.masterTradingAccountId._id === selectedAccount.value
      )[0];
      return filteredAccount.recordId;
    }
    return null;
  }, [selectedAccount]);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "sp" } }));
  }, []);

  useEffect(() => {
    if (profiles && profiles.length) {
      setSelectedAccount({
        label: profiles[0].masterTradingAccountId.login,
        value: profiles[0].masterTradingAccountId._id,
      });
    }
  }, [profiles]);

  return (
    <Container className="mt-5">
      <div className="page-content">
        <h1 className="color-primary">{t("Dedicated Links")}</h1>
        <PageHeader title="Dedicated Links"></PageHeader>
      </div>
      <CustomSelect
        name="user"
        className="w-25"
        value={selectedAccount}
        options={
          profiles &&
          profiles.length &&
          profiles.map((profile) => ({
            label: profile.masterTradingAccountId.login,
            value: profile.masterTradingAccountId._id,
          }))
        }
        onChange={(newVal) => {
          setSelectedAccount(newVal);
        }}
      />
      <SpDedicatedLinks agRef={selectedRecordId} />
    </Container>
  );
};

export default MyDedicatedLinks;
