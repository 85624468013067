import qs from "qs";
import * as axiosHelper from "../api_helper";

export const getProfilesAPI = async ({ params }) => {
  try {
    return await axiosHelper.get(`/mam-profile/profiles?${qs.stringify(params)}`);
  } catch (error) {
    throw new Error("Error while fetching mam-profiles");
  }
};

export const getAllProfilesAPI = async ({ params }) => {
  try {
    return await axiosHelper.get(`/mam-profile/all-profiles?${qs.stringify(params)}`);
  } catch (error) {
    throw new Error("Error while fetching mam-profiles");
  }
};

export const getProfileAPI = async ({ _id, params }) => {
  try {
    return await axiosHelper.get(`/mam-profile/profiles/${_id}?${qs.stringify(params)}`);
  } catch (error) {
    throw new Error("Error while fetching mam-profile");
  }
};

export const getFeesDeductedAPI = async ({ params }) => {
  try {
    return await axiosHelper.get(`/mam-profile/fees-deducted?${qs.stringify(params)}`);
  } catch (error) {
    throw new Error("Error while fetching mam-profiles");
  }
};
