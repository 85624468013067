/* eslint-disable object-curly-newline */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getMamProfileFailAction,
  getMamProfileSuccessAction,
  getMamProfilesFailAction,
  getMamProfilesSuccessAction,
} from "./actions";
import { getProfileAPI, getProfilesAPI } from "apis/forex/mam_pam";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import { GET_MAM_PROFILES_START, GET_MAM_PROFILE_START } from "./actionTypes";

function* getProfiles({ payload }) {
  try {
    const data = yield call(getProfilesAPI, payload);
    const { result, status } = data;
    if (status) {
      yield put(getMamProfilesSuccessAction(result));
    }
  } catch (error) {
    yield put(getMamProfilesFailAction(error));
    yield put(showErrorNotification(error.message));
  }
}

//profile
function* getProfile({ payload }) {
  try {
    const result = yield call(getProfileAPI, payload);
    yield put(getMamProfileSuccessAction(result));
    yield put(showSuccessNotification(result));
  } catch (error) {
    yield put(getMamProfileFailAction(error));
    yield put(showErrorNotification(error.message));
  }
}

function* fetchProfilesSaga() {
  yield takeEvery(GET_MAM_PROFILES_START, getProfiles);
  yield takeEvery(GET_MAM_PROFILE_START, getProfile);
}

export default fetchProfilesSaga;
