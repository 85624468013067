import CustomSelect from "components/Common/CustomSelect";
import Loader from "components/Common/Loader";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets } from "store/actions";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { max } from "lodash";

export default function FiatFormDetails(props) {
  const {
    t,
    selectedWallet,
    amount,
    setAmount,
    setSelectedWallet,
    setAmountValidation,
    minDepositAmount = 15,
  } = props;
  const dispatch = useDispatch();
  const [amountError, setAmountError] = useState(false);
  const [minAmount, setMinAmount] = useState(minDepositAmount ?? 0);
  const { wallets, loading } = useSelector((state) => state?.walletReducer);

  useEffect(() => {
    if (amount < minAmount) {
      setAmountError(true);
      setAmountValidation(true);
    } else {
      setAmountError(false);
      setAmountValidation(false);
    }
  }, [amount, minAmount, selectedWallet]);

  useEffect(() => {
    const sWallet = wallets?.find((wallet) => wallet._id === selectedWallet?.value);
    const minAmount =
      parseInt(sWallet?.assetId?.minAmount?.deposit) > minDepositAmount
        ? parseInt(sWallet?.assetId?.minAmount?.deposit)
        : minDepositAmount;
    setMinAmount(minAmount);
  }, [selectedWallet]);

  useEffect(() => {
    if (wallets?.length > 0) {
      setSelectedWallet({
        label: wallets[0].asset,
        value: wallets[0]._id,
        image: getAssetImgSrc(wallets[0].assetId),
      });
      const minAmount =
        parseInt(wallets[0].assetId?.minAmount?.deposit) > minDepositAmount
          ? parseInt(wallets[0].assetId?.minAmount?.deposit)
          : minDepositAmount;
      setMinAmount(minAmount);
    }
  }, [wallets]);

  const loadWallets = () => {
    dispatch(fetchWallets());
  };

  useEffect(() => {
    if (!wallets || wallets?.length === 0) {
      loadWallets({
        isCrypto: false,
        isInventory: false,
      });
    }
  }, []);

  const fiatWallets = wallets?.map((wallet) => {
    return {
      label: wallet.asset,
      value: wallet._id,
      image: getAssetImgSrc(wallet.assetId),
    };
  });

  return (
    <div className="w-100">
      {loading ? (
        <Loader />
      ) : (
        <>
          {t("Transaction requirements")}
          <i className="fas fa-info-circle ms-2"></i>
          <div className="mt-3">
            <InputGroup>
              <Input
                required
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                className="form-control"
                type="number"
                min="0"
                value={amount}
                placeholder={`Enter ${minAmount}-128341`}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <InputGroupText className="custom-input-group-text p-0 border-0">
                <div
                  style={{
                    width: "127px",
                    height: "100%",
                  }}
                >
                  <CustomSelect
                    placeholder="Select"
                    name="wallets"
                    isRequired
                    options={fiatWallets}
                    defaultValue={fiatWallets[0]}
                    onChange={(e) => {
                      setSelectedWallet(e);
                    }}
                  ></CustomSelect>
                </div>
              </InputGroupText>
            </InputGroup>
          </div>
          {amountError && (
            <p className="small text-danger ">
              {" "}
              {!amount
                ? t("Amount is required")
                : t("Amount must be greater than " + minAmount)}{" "}
            </p>
          )}
          <div className="text-center fw-bolder mt-4 received-amount">
            <span className="fs-5">{selectedWallet?.label}</span>
            <span className="fs-1">{amount}</span>
          </div>
        </>
      )}
    </div>
  );
}
