import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { Container, FormGroup, Input, Label } from "reactstrap";
import ToMyAccount from "./ToMyAccount";
import ToIBMasterAccount from "./ToIBMasterAccount";
import { useSelector } from "react-redux";

function InternalTransfer() {
  const { t } = useTranslation();
  const [transferDestination, setTransferDestination] = useState("toMyAccount");
  const { clientData } = useSelector((state) => state.Profile);

  return (
    <>
      <MetaTags>
        <title>{t("Internal Transfer")}</title>
      </MetaTags>
      <div className="page-content">
        <Container className="pt-5">
          <PageHeader title="Internal Transfer"></PageHeader>
          <CardWrapper className="mt-4">
            <div className="d-flex justify-content-between heading pb-2">
              <h5>{t("Internal Transfer")}</h5>
              <div className="d-flex">
                <FormGroup check className="my-auto me-3">
                  <Input
                    id="toMyAccount"
                    name="toMyAccount"
                    type="radio"
                    checked={transferDestination === "toMyAccount"}
                    onChange={() => {
                      setTransferDestination("toMyAccount");
                    }}
                  />
                  <Label check for="toMyAccount">
                    {t("To My Account")}
                  </Label>
                </FormGroup>
                {clientData?.parentId && (
                  <FormGroup check className="my-auto">
                    <Input
                      id="toIBMasterAccount"
                      name="toIBMasterAccount"
                      type="radio"
                      checked={transferDestination === "toIBMasterAccount"}
                      onChange={() => {
                        setTransferDestination("toIBMasterAccount");
                      }}
                    />
                    <Label check for="toIBMasterAccount">
                      {t("To IB Master Account")}
                    </Label>
                  </FormGroup>
                )}
              </div>
            </div>
            {transferDestination === "toMyAccount" && <ToMyAccount t={t}></ToMyAccount>}
            {transferDestination === "toIBMasterAccount" && clientData?.parentId && (
              <ToIBMasterAccount t={t}></ToIBMasterAccount>
            )}
          </CardWrapper>
        </Container>
      </div>
    </>
  );
}

export default InternalTransfer;
