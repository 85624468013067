import CardWrapper from "components/Common/CardWrapper";
import { cpUrl } from "content";
import React from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "reactstrap";
import { showSuccessNotification } from "store/actions";
import AffiliateTree from "./AffiliateTree";

export default function AffiliateList() {
  const { t } = useTranslation();
  const { clientData } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();
  const getLink = () => {
    return `${cpUrl}/register/forex/live?refId=${clientData?.recordId}`;
  };

  return (
    <Container>
      <div className="page-content mt-5">
        <MetaTags>
          <title>{t("Affiliates")}</title>
        </MetaTags>
        <CardWrapper className="mt-3 px-5 py-4 glass-card shadow">
          <Row className="my-2">
            <Col xs={12} md={6} className="text-center text-md-start">
              <h4 className="color-primary">{t("Affiliates")}</h4>
            </Col>
            <Col xs={12} md={6} className="text-center text-md-end">
              <h4 className="color-primary">
                {"Your Max Income Level: "} {clientData?.referralTree?.length * 2 || 0}
              </h4>
            </Col>
          </Row>
          <Row className="text-center my-4">
            <h6 className="color-primary">
              {t("Your Affiliate Link")}: {getLink()}
              <Link
                to="#"
                className="mdi mdi-clipboard-check-multiple-outline font-size-20 me-2"
                onClick={() => {
                  navigator.clipboard.writeText(getLink());
                  dispatch(showSuccessNotification("Link copied to clipboard"));
                }}
              ></Link>
            </h6>
          </Row>
        </CardWrapper>
      </div>
      <CardWrapper className="mt-3 px-5 py-4 glass-card shadow">
        <Row>
          <h4 className="color-primary">{t("Affiliates")}</h4>
        </Row>
        <Row>
          <AffiliateTree />
        </Row>
      </CardWrapper>
    </Container>
  );
}
