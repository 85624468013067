/* eslint-disable indent */
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Row } from "reactstrap";
import { useEffect } from "react";
import classNames from "classnames";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CardWrapper from "components/Common/CardWrapper";
import { setSelectedMamProfile } from "store/forex/mamProfile/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toggleCurrentModal } from "store/actions";
import LinkInvestorModal from "../../SignalProvider/Accounts/LinkInvestorModal";
import UnlinkInvestorModal from "../../SignalProvider/Accounts/UnlinkInvestorModal";
import PendingRequestBadge from "components/Forex/Common/PendingRequestBadge";
import { JClickHandler } from "components/Journey/handlers";

const { SingleValue, Option } = components;

const AccountSelect = (props) => {
  const { layoutMode } = useSelector((state) => state.Layout);
  const { t } = useTranslation();

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div className="color-primary font-weight-bold border-0" style={{ fontSize: "14.4px" }}>
        {t("Account No")}
      </div>
      <div style={{ fontWeight: "bold" }}>{props.data.label}</div>
    </SingleValue>
  );

  const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "#495057",
      padding: 0,
      backgroundColor: "transparent",
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      alignItems: "start",
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#1A2A3E",
          border: "0 solid #CED4DA",
          boxShadow: "0 0.125rem 0.25rem #1e2128",
          color: "#adb5bd",
          height: "100%",
          borderRadius: "10px",
          padding: "10px",
          alignItems: "start",
        };
      }
      return {
        ...provided,
        borderRadius: "10px",
        padding: "17px",
        height: "100%",
        border: "0px",
        boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 10%)",
        cursor: "pointer",
        background: "transparent",
        alignItems: "start",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      padding: "10px",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <Select
          {...props}
          className={"basic-single color-primary shadow border-0"}
          placeholder={t("No master account found")}
          styles={customStyles}
          onInputChange={(input) => {
            if (input !== "") {
              if (!/^-?\d*\.?\d*$/.test(input)) {
                return "";
              }
            }
            return input;
          }}
          components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
          }}
          options={props.options}
        />
      </div>
    </>
  );
};

const SpAccountDetail = (props) => {
  const { profiles, operations, selectedTradingAccount } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedMamAccount: selectedAccount } = useSelector((state) => state.forex.mamProfile);
  const setSelectedAccount = (value) => {
    dispatch(setSelectedMamProfile(value));
  };
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const { clientData } = useSelector((state) => ({
    clientData: state.Profile.clientData || {},
  }));

  useEffect(() => {
    if (profiles && profiles.length) {
      setSelectedAccount({
        label: profiles[0].masterTradingAccountId.login,
        value: profiles[0].masterTradingAccountId._id,
      });
    }
  }, [profiles]);

  let activeInvestors = 0;
  let inactiveInvestors = 0;

  if (profiles && profiles.length) {
    profiles.forEach((profile) => {
      profile.slaves.forEach((slave) => {
        if (slave.isActive === true) {
          activeInvestors++;
        } else if (slave.status === false) {
          inactiveInvestors++;
        }
      });
    });
  }

  const getProfileName = (profileType) => {
    switch (profileType) {
      case 1:
        return "Equity Lot Share - MAM";
      case 2:
        return "Equity Cash Share - PAM";
      case 9:
        return "Copy Trading - Social Trading";
      default:
        return "";
    }
  };

  return (
    <>
      <h1 className="color-primary">{t("Master Account Detail")}</h1>
      <CardWrapper className="accounts-tab shadow mt-4 glass-card">
        {operations && (
          <Row className="d-flex align-items-center">
            <Col className="d-inline-block d-md-flex justify-content-end">
              <div className="d-flex gap-2">
                <Button
                  color="light"
                  className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={JClickHandler(
                    "NewMamProfile",
                    {
                      ...clientData.stages,
                    },
                    dispatch,
                    toggleCurrentModal,
                    "SP"
                  )}
                >
                  {t("New Profile")}
                </Button>
                <Button
                  color="light"
                  className="btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={() => history.push("/accounts/password")}
                  disabled={profiles && profiles.length === 0}
                >
                  {t("Change Password")}
                </Button>
                <Button
                  color="light"
                  className=" btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={() => dispatch(toggleCurrentModal("LinkInvestorModal"))}
                  disabled={profiles && profiles.length === 0}
                >
                  {t("Link Investor")}
                </Button>
                <Button
                  color="light"
                  className=" btn-light my-2 mx-1 shadow-lg bg-white border-0 color-bg-btn btn btn-secondary"
                  onClick={() => dispatch(toggleCurrentModal("UnlinkInvestorModal"))}
                  disabled={profiles && profiles.length === 0}
                >
                  {t("Unlink Investor")}
                </Button>
              </div>
            </Col>
          </Row>
        )}

        <Row className="mt-3" style={{ "--bs-gutter-y": "20px" }}>
          {profiles && profiles.length === 0 ? (
            <>
              <Col lg={12} md={12} xs={12} className="text-center w-100 p-5">
                <h5 className="color-primary">{t("No Profile Found!")}</h5>
              </Col>
            </>
          ) : (
            <>
              <Col lg={2} md={4} xs={12} className="flex-fill">
                <div className="mb-3 h-100">
                  <AccountSelect
                    options={
                      profiles && profiles.length
                        ? profiles.map((profile) => ({
                            label: profile.masterTradingAccountId.login,
                            value: profile.masterTradingAccountId._id,
                          }))
                        : []
                    }
                    value={selectedAccount}
                    onChange={(newVal) => {
                      setSelectedAccount(newVal);
                    }}
                  />
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Allocation Type")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{getProfileName(selectedTradingAccount?.profileType)}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Fee Account")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeTradingAccountId?.login ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Performance Fee - (%)")}
                    </div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeSetting?.performanceFee ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Management Fee - ($)")}
                    </div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeSetting?.managementFee ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Per Lot Fee - ($)")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeSetting?.perLotFee ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Periodic Management Fee - ($)")}
                    </div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeSetting?.periodicManagementFee ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Active Investor")}</div>
                    <div className="acc-tab-card-desc">{activeInvestors}</div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Inactive Investor")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{inactiveInvestors}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Balance")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.masterTradingAccountId?.Balance ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Credit")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.masterTradingAccountId?.Credit ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Margin")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.masterTradingAccountId?.Margin ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">{t("Total Balance")}</div>
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.masterTradingAccountId?.Storage ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
              {selectedTradingAccount?.username && (
                <Col lg={2} md={4} xs={12}>
                  <div
                    className={classNames(
                      "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                    )}
                  >
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="text-start w-100 p-0 mb-1 btn-white"
                    >
                      <div className="acc-tab-card-title color-primary">{t("Username")}</div>
                      <div className="acc-tab-card-desc">
                        {selectedTradingAccount?.username ?? "-"}
                      </div>
                    </button>
                  </div>
                </Col>
              )}
              <Col lg={2} md={4} xs={12}>
                <div
                  className={classNames(
                    "acc-tab-card h-100 text-center mb-3 shadow-lg border border-0"
                  )}
                >
                  <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-start w-100 p-0 mb-1 btn-white"
                  >
                    <div className="acc-tab-card-title color-primary">
                      {t("Fee Account Balance")}
                    </div>
                    {console.log(selectedTradingAccount)}
                    <div className="acc-tab-card-desc">
                      <h6>{selectedTradingAccount?.feeTradingAccountId?.Equity ?? "-"}</h6>
                    </div>
                  </button>
                </div>
              </Col>
            </>
          )}
        </Row>
      </CardWrapper>
      {currentModal === "LinkInvestorModal" && (
        <LinkInvestorModal
          isOpen={currentModal === "LinkInvestorModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
          profiles={profiles}
        />
      )}
      {currentModal === "UnlinkInvestorModal" && (
        <UnlinkInvestorModal
          isOpen={currentModal === "UnlinkInvestorModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
          profiles={profiles}
        />
      )}
    </>
  );
};

export default SpAccountDetail;
