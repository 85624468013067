export const GET_WITHDRAWALS_START = "GET_WITHDRAWALS_START";
export const GET_WITHDRAWALS_SUCCESS = "GET_WITHDRAWALS_SUCCESS";
export const GET_DEPOSITS_START = "GET_DEPOSITS_START";
export const GET_DEPOSITS_SUCCESS = "GET_DEPOSITS_SUCESS";
export const GET_CONVERT_START = "GET_CONVERT_START";
export const GET_CONVERT_SUCCESS = "GET_CONVERT_SUCCESS";
export const API_ERROR = "API_ERROR";

// deposit gateways
export const FETCH_DEPOSIT_GATEWAY_REQUESTED = "FETCH_DEPOSIT_GATEWAY_REQUESTED";
export const FETCH_DEPOSIT_GATEWAY_SUCCESS = "FETCH_DEPOSIT_GATEWAY_SUCCESS";
export const FETCH_DEPOSIT_GATEWAY_FAIL = "FETCH_DEPOSIT_GATEWAY_FAIL";

// withdrawal gateways
export const FETCH_WITHDRAWAL_GATEWAY_REQUESTED = "FETCH_WITHDRAWAL_GATEWAY_REQUESTED";
export const FETCH_WITHDRAWAL_GATEWAY_SUCCESS = "FETCH_WITHDRAWAL_GATEWAY_SUCCESS";
export const FETCH_WITHDRAWAL_GATEWAY_FAIL = "FETCH_WITHDRAWAL_GATEWAY_FAIL";
