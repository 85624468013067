import { Container } from "reactstrap";
import FeesCollected from "./FeesCollected";
import FeesNotCollected from "./FeesNotCollected";
import { withTranslation } from "react-i18next";
import PageHeader from "components/Forex/Common/PageHeader";

const Transaction = () => {
  return (
    <Container className="mt-5">
      <div className="page-content">
        <PageHeader title="Transaction"></PageHeader>
      </div>
      <div className="pt-5">
        <FeesNotCollected />
      </div>
      <FeesCollected />
    </Container>
  );
};

export default withTranslation()(Transaction);
