import { JClickHandler } from "components/Journey/handlers";
// eslint-disable-next-line object-curly-newline
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { toggleCurrentModal } from "store/actions";

const SpJourney = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [stages, setStages] = useState({
    kycApproved: false,
    kycUpload: false,
    kycRejected: false,
    startTrading: false,
    individual: { submitProfile: false },
    sp: { configureProfile: false },
    loaded: false,
  });

  useEffect(() => {
    if (props.stages) {
      setStages({
        ...stages,
        loaded: true,
        kycUpload: props.stages.kycUpload,
        kycApproved: props.stages.kycApproved,
        kycRejected: props.stages.kycRejected,
        startTrading: props.stages.startTrading,
        individual: {
          ...props.stages.individual,
          submitProfile: props.stages.individual && props.stages.individual.submitProfile,
        },
        sp: {
          ...props.stages.sp,
          configureProfile: props.stages.sp && props.stages.sp.configureProfile,
        },
      });
    }
  }, [props.stages]);

  useEffect(() => {
    if (props.clientData) {
      if (props.stages && stages.loaded) {
        if (!stages.individual.submitProfile) {
          JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.kycUpload) {
          JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.sp.configureProfile) {
          JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)();
        } else if (!stages.startTrading) {
          JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)();
        }
      }
    }
  }, [stages, props.stages]);

  if (
    stages.individual.submitProfile &&
    stages.kycApproved &&
    stages.sp.configureProfile &&
    stages.startTrading
  ) {
    return <></>;
  }

  return (
    <div className="mb-5">
      {props.clientData && !props.clientData.isLead && (
        <Fragment>
          <div className="steps mb-5">
            <div
              className={
                stages.individual.submitProfile
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">1</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Sign up")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>

            <div
              className={
                stages.kycApproved
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">2</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Verify")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.sp.configureProfile
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">3</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Configure Profile")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
            <div
              className={
                stages.startTrading
                  ? "steps-card steps-complete text-center"
                  : "steps-card text-center"
              }
              onClick={JClickHandler("spJourney", props.stages, dispatch, toggleCurrentModal)}
            >
              <div className="number">4</div>
              <div className="steps-card-title-container mb-3">
                <span className="steps-card-title">
                  {t("Start Trading")}
                  <span className="custom-border"></span>
                </span>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stages: (state.Profile.clientData && state.Profile.clientData.stages) || {},
  clientData: state.Profile.clientData || {},
});
export default connect(mapStateToProps, null)(SpJourney);
