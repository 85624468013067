/* eslint-disable object-curly-newline */
import { withTranslation } from "react-i18next";
import FeesDeducted from "pages/Forex/Dashboard/InvestorPortal/FeesDeducted";
import InvestorAccountDetail from "pages/Forex/Dashboard/InvestorPortal/InvestorAccountDetail";
import { Container } from "reactstrap";
import PageHeader from "components/Forex/Common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";
import { getAccountsStart } from "store/actions";

const InvestorAccounts = () => {
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.forex.mamProfile);
  const { clientData = {} } = useSelector((state) => state.Profile);
  const { accounts } = useSelector((state) => state.forex?.accounts);

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [investorAccounts, setInvestorAccounts] = useState([]);

  useEffect(() => {
    dispatch(getAccountsStart({}));
  }, []);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "investor" } }));
  }, []);

  useEffect(() => {
    if (investorAccounts?.length > 0) {
      setSelectedProfile(investorAccounts[0]);
    }
  }, [investorAccounts]);

  useEffect(() => {
    const newInvestorAccounts = [];
    if (profiles?.length > 0) {
      profiles.forEach((profile) => {
        profile.slaves.forEach((slave) => {
          if (slave.customerId._id === clientData?._id) {
            newInvestorAccounts.push({
              ...profile,
              selectedInvestorAccount: slave,
              investorId: slave._id,
            });
          }
        });
      });
    }
    if (accounts?.length > 0) {
      accounts
        ?.filter((a) => a.type === "INVESTOR")
        .forEach((account) => {
          // need to check if account is already added by checking investor id of profiles
          const isAlreadyAdded = newInvestorAccounts.find(
            (item) => item.investorId === account._id
          );
          if (!isAlreadyAdded) {
            newInvestorAccounts.push({
              selectedInvestorAccount: {
                ...account,
                tradingAccountId: account._id,
              },
              investorId: account._id,
              _id: account._id,
            });
          }
        });
    }
    setInvestorAccounts(newInvestorAccounts);
  }, [profiles, accounts]);

  return (
    <Container className="mt-5 forex-dashboard">
      <div className="page-content">
        <PageHeader title="Accounts"></PageHeader>
      </div>
      <div className="pt-5">
        <InvestorAccountDetail
          selectedProfile={selectedProfile}
          profiles={investorAccounts}
          operations={true}
          setSelectedProfile={setSelectedProfile}
        />
      </div>
      <div className="py-5">
        <FeesDeducted selectedProfile={selectedProfile} />
      </div>
    </Container>
  );
};
export default withTranslation()(InvestorAccounts);
