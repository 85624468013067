import CardWrapper from "components/Common/CardWrapper";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import LinkModal from "./LinkModal";
import { getAllProfilesAPI } from "apis/forex/mam_pam";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toggleCurrentModal } from "store/actions";
import { JClickHandler } from "components/Journey/handlers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProfileBasicStats from "./ProfileBasicStats";

export default function MasterDetails() {
  const { t } = useTranslation();

  const { clientData } = useSelector((state) => state.Profile);
  const { currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [masterTraders, setMasterTraders] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getAllProfilesAPI({
      params: {
        page: 1,
        limit: 1000,
      },
    })
      .then((res) => {
        if (res.result) {
          const newMasterTraders = res.result?.docs?.map((item) => ({
            _id: item._id,
            name: `${capitalize(item.customerId?.firstName)} ${capitalize(item.customerId?.lastName)}`,
            investors: item.slaves?.length,
            profileId: item._id,
            equity: item.masterTradingAccountId?.Equity ?? 0,
            login: item.masterTradingAccountId?.login,
            ...item,
          }));
          setMasterTraders(newMasterTraders);
        } else {
          setMasterTraders([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setMasterTraders([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const history = useHistory();

  const getProfileName = (profileType) => {
    switch (profileType) {
      // case 1 : return " MAM";
      case 2:
        return " PAM";
      case 9:
        return " Copy Trading/Social Trading";
      default:
        return "";
    }
  };

  return (
    <div className="mb-3">
      <h1 className="color-primary">{t("Master Traders")}</h1>
      <Row className="mt-2">
        {loading ? (
          <div className="text-center w-100">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <>
            {masterTraders
              ?.sort(
                (a, b) =>
                  b.masterTradingAccountId?.totalProfit - a.masterTradingAccountId?.totalProfit
              )
              .sort((a, b) => b.investors - a.investors)
              // need to show only 8
              .slice(0, masterTraders?.length > 4 ? 4 : masterTraders?.length)
              .map((masterTrader, key) => (
                <Col key={key} md={3} className="my-2">
                  <CardWrapper className="accounts-tab shadow glass-card">
                    <CardTitle className="text-center">
                      <ProfileBasicStats profile={masterTrader} />
                    </CardTitle>
                    <CardBody className="my-3 text-center">
                      <div className="d-flex justify-content-between">
                        <h5>{t("Username")}: </h5>
                        <h5 className="color-primary">{masterTrader.username ?? 0}</h5>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h5>{t("Equity")}: </h5>
                        <h5 className="color-primary">{masterTrader.equity ?? 0}</h5>
                      </div>
                      <div className="d-flex justify-content-between my-1">
                        <h5>{t("Profile Type")}: </h5>
                        <h5 className="color-primary">
                          {getProfileName(masterTrader.profileType)}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between my-2">
                        <h5>{t("Investors")}: </h5>
                        <h5 className="color-primary">{masterTrader.investors ?? 0}</h5>
                      </div>
                      {/* <div className="d-flex justify-content-between my-2">
                      <h5>{t("Master Login")}: </h5>
                      <h5 className="color-primary">{masterTrader.login ?? "-"}</h5>
                    </div> */}
                      <div className="d-flex justify-content-between">
                        <h5>{t("Total Profit Made")}: </h5>
                        <h5 className="color-primary">
                          {masterTrader.masterTradingAccountId?.totalProfit ?? 0}
                        </h5>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className="btn btn-primary color-bg-btn border-0"
                        color="primary w-100"
                        onClick={() => {
                          setProfile(masterTrader);
                          JClickHandler(
                            "Invest",
                            {
                              ...clientData.stages,
                              loaded: true,
                            },
                            dispatch,
                            toggleCurrentModal,
                            "INVESTOR"
                          )();
                        }}
                      >
                        {t("Invest")}
                      </Button>
                    </CardFooter>
                  </CardWrapper>
                </Col>
              ))}
          </>
        )}
        {!loading && masterTraders?.length !== 0 && (
          <Col md={12} className="text-center mt-3">
            <Button
              className="btn btn-primary color-bg-btn border-0"
              color="primary"
              onClick={() => {
                history.push("/master-traders");
              }}
            >
              {t("View All")}
            </Button>
          </Col>
        )}
      </Row>
      {currentModal === "Invest" && profile?._id && (
        <LinkModal
          isOpen={currentModal === "Invest"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
          profileId={profile?._id}
          selectedProfile={profile}
        />
      )}
    </div>
  );
}
