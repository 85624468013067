import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCurrentModal } from "../../store/actions";
import SelectDepositMethod from "components/Forex/Deposit/SelectDepositMethod";
import SelectAccType from "components/Forex/Accounts/SelectAccType";
import UploadKycModal from "components/Journey/UploadKycModal";
import StartTrading from "components/Journey/StartTrading";
import { useHistory } from "react-router";
import KYCProgress from "components/Journey/KYCProgress";
import LinkProgress from "components/Journey/LinkProgress";
import IbQuestionaire from "components/Journey/IbQuestionaire";
import Profiles from "components/Journey/Profiles";
import StartTradingModal from "pages/Forex/Dashboard/SpPortal/StartTradingModal";
import NewMamProfile from "pages/Forex/SignalProvider/AllocationProfile/NewMamProfile";
import CreateInvestorAccountModal from "pages/Forex/Investor/Accounts/CreateInvestorAccountModal";
import ProfileRequest from "pages/Forex/Dashboard/SpPortal/ProfileRequest";

function JourneyModals() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentModal = useSelector((state) => state.Layout.currentModal);
  return (
    <>
      {currentModal === "SubmitIndProfile" && (
        <Profiles
          isOpen={currentModal === "SubmitIndProfile"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
          t={(str) => {
            return str;
          }}
        ></Profiles>
      )}
      {currentModal === "JourneyCreateAccount" && (
        <SelectAccType
          isOpen={currentModal === "JourneyCreateAccount"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></SelectAccType>
      )}
      {currentModal === "UploadKycModal" && (
        <UploadKycModal
          t={(str) => {
            return str;
          }}
          show={currentModal === "UploadKycModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></UploadKycModal>
      )}
      {currentModal === "JourneyDeposit" && (
        <SelectDepositMethod
          isOpen={currentModal === "JourneyDeposit"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></SelectDepositMethod>
      )}
      {currentModal === "KYCProgress" && (
        <KYCProgress
          t={(str) => {
            return str;
          }}
          isOpen={currentModal === "KYCProgress"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></KYCProgress>
      )}
      {currentModal === "LinkProgress" && (
        <LinkProgress
          t={(str) => {
            return str;
          }}
          isOpen={currentModal === "LinkProgress"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></LinkProgress>
      )}
      {currentModal === "StartTrading" && (
        <StartTrading
          history={history}
          t={(str) => {
            return str;
          }}
          show={currentModal === "StartTrading"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        />
      )}
      {currentModal === "IBQuestionnaire" && (
        <IbQuestionaire
          isOpen={currentModal === "IBQuestionnaire"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        />
      )}
      {currentModal === "StartTradingModal" && (
        <StartTradingModal
          history={history}
          t={(str) => {
            return str;
          }}
          show={currentModal === "StartTradingModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        />
      )}
      {currentModal === "NewMamProfile" && (
        <NewMamProfile
          isOpen={currentModal === "NewMamProfile"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        />
      )}
      {currentModal === "CreateInvestorAccountModal" && (
        <CreateInvestorAccountModal
          isOpen={currentModal === "CreateInvestorAccountModal"}
          toggle={() => {
            dispatch(toggleCurrentModal(""));
          }}
        />
      )}
      {currentModal === "ProfileRequest" && (
        <>
          <ProfileRequest
            isOpen={currentModal === "ProfileRequest"}
            toggle={() => {
              dispatch(toggleCurrentModal(""));
            }}
          />
        </>
      )}
    </>
  );
}

export default JourneyModals;
