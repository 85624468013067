import { JClickHandler } from "components/Journey/handlers";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Td, Tr } from "react-super-responsive-table";
import { Button } from "reactstrap";
import { toggleCurrentModal } from "store/actions";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export default function RowView({ masterTrader, setProfile }) {
  const { clientData } = useSelector((state) => state.Profile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getProfileName = (profileType) => {
    switch (profileType) {
      // case 1 : return "Equity Lot Share - MAM";
      case 2:
        return "Equity Cash Share - PAM";
      case 9:
        return " Copy Trading/Social Trading";
      default:
        return "";
    }
  };
  return (
    <Tr className="my-2 text-center py-5">
      <Td></Td>
      <Td>
        {/* <img
          src={`https://ui-avatars.com/api/?name=${masterTrader.name}&background=00C6C1&color=fff`}
          alt="profile"
          className="rounded-circle"
          width="50"
          height="50"
        /> */}
      </Td>
      <Td>{masterTrader.username && <h4 className="color-primary">{masterTrader.username}</h4>}</Td>
      <Td>
        <h4 className="color-primary">{getProfileName(masterTrader.profileType)}</h4>
      </Td>
      <Td>
        <h5 className="color-primary">{masterTrader.investors ?? 0}</h5>
      </Td>
      <Td>
        <h5 className="color-primary">{masterTrader.Equity ?? 0}</h5>
      </Td>
      <Td>
        <h5 className="color-primary">
          {masterTrader?.masterTradingAccountId?.totalProfit ?? 0 ?? 0}
        </h5>
      </Td>
      <Td>
        <h5 className="color-primary">
          {
            // no of days since today
            Math.floor((new Date() - new Date(masterTrader.createdAt)) / (1000 * 60 * 60 * 24))
          }
        </h5>
      </Td>
      <Td className="m-2">
        <Button
          className="btn btn-primary color-bg-btn border-0"
          color="primary w-100"
          onClick={() => {
            setProfile(masterTrader);
            JClickHandler(
              "Invest",
              {
                ...clientData.stages,
                loaded: true,
              },
              dispatch,
              toggleCurrentModal,
              "INVESTOR"
            )();
          }}
        >
          {t("Invest")}
        </Button>
      </Td>
    </Tr>
  );
}
