import {
  GET_CP_INVESTOR_DEPOSITS_START,
  GET_CP_INVESTOR_WITHDRAWALS_START,
  GET_CP_FEETRADING_WITHDRAWALS_START,
  GET_CP_MASTER_DEPOSITS_SUCCESS,
  GET_CP_INVESTOR_DEPOSITS_SUCCESS,
  GET_CP_INVESTOR_WITHDRAWALS_SUCCESS,
  GET_CP_FEETRADING_WITHDRAWALS_SUCCESS,
  GET_CP_MASTER_DEPOSITS_START,
} from "./actionTypes";

const initialState = {
  loading: false,
  deposits: { docs: [] },
  masterDeposit: {
    docs: [],
    loading: false,
    submitting: false,
  },
  investorWithdraw: {
    docs: [],
    loading: false,
    submitting: false,
  },
  investorDeposit: {
    docs: [],
    loading: false,
    submitting: false,
  },
  feeTradingWithdraw: {
    docs: [],
    loading: false,
    submitting: false,
  },
};

const cpTransactions = (state = initialState, action) => {
  switch (action.type) {
    // case GET_CP_WITHDRAWALS_START:
    //   return {
    //     ...state,
    //     withdraws: {
    //       ...state.withdraws,
    //       loading: true,
    //     },
    //   };
    case GET_CP_MASTER_DEPOSITS_START:
      return {
        ...state,
        masterDeposit: {
          ...state.masterDeposit,
          loading: true,
        },
      };
    case GET_CP_INVESTOR_DEPOSITS_START:
      return {
        ...state,
        investorDeposit: {
          ...state.investorDeposit,
          loading: true,
        },
      };
    case GET_CP_INVESTOR_WITHDRAWALS_START:
      return {
        ...state,
        investorWithdraw: {
          ...state.investorWithdraw,
          loading: true,
        },
      };
    case GET_CP_FEETRADING_WITHDRAWALS_START:
      return {
        ...state,
        feeTradingWithdraw: {
          ...state.feeTradingWithdraw,
          loading: true,
        },
      };
    case GET_CP_MASTER_DEPOSITS_SUCCESS:
      return {
        ...state,
        loading: false,
        masterDeposit: {
          loading: false,
          ...action.payload,
        },
      };
    case GET_CP_INVESTOR_DEPOSITS_SUCCESS:
      return {
        ...state,
        loading: false,
        investorDeposit: {
          loading: false,
          ...action.payload,
        },
      };
    case GET_CP_INVESTOR_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        loading: false,
        investorWithdraw: {
          loading: false,
          ...action.payload,
        },
      };
    case GET_CP_FEETRADING_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        loading: false,
        feeTradingWithdraw: {
          loading: false,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
export default cpTransactions;
