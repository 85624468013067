import React, { useCallback, useState } from "react";
import Tree from "react-d3-tree";
import { useSelector } from "react-redux";

const useCenteredTree = (
  defaultTranslate = {
    x: 0,
    y: 0,
  }
) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({
        width,
        height,
      });
      setTranslate({
        x: width / 2,
        y: height / 2,
      });
    }
  }, []);
  return [dimensions, translate, containerRef];
};

export default function AffiliateTree() {
  const { clientData } = useSelector((state) => state.Profile);

  const customerNodeRender = ({ nodeDatum, toggleNode }) => {
    return (
      <g>
        <rect width="20" height="20" onClick={toggleNode} />
        <text fill="black" strokeWidth="1" y={"-10"}>
          {nodeDatum.name}
        </text>
      </g>
    );
  };

  const getChildren = (referralTree, parentId, level) => {
    if (!referralTree) {
      return [];
    }

    return referralTree
      ?.filter((item) => item.depth === level && item.referrerId === parentId)
      ?.map((item) => {
        return {
          name: `${item.firstName} ${item.lastName}`,
          children: getChildren(referralTree, item._id, level + 1),
        };
      });
  };

  const getChart = () => {
    const tree = {
      name: `${clientData?.firstName} ${clientData?.lastName}`,
      children: getChildren(clientData?.referralTree, clientData._id, 0),
    };
    return tree;
  };
  const [dimensions, translate, containerRef] = useCenteredTree();

  return (
    <>
      <div
        id="treeWrapper"
        style={{
          width: "100%",
          height: "50vh",
        }}
        ref={containerRef}
      >
        <Tree
          data={getChart(clientData)}
          dimensions={dimensions}
          translate={translate}
          renderCustomNodeElement={customerNodeRender}
          orientation="vertical"
        />
      </div>
    </>
  );
}
