import React from "react";

export default function profileSvg(props) {
  const { fill } = props;
  return (
    <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99944 12.8573C11.7746 12.8573 13.2137 11.4182 13.2137 9.643C13.2137 7.8678 11.7746 6.42871 9.99944 6.42871C8.22424 6.42871 6.78516 7.8678 6.78516 9.643C6.78516 11.4182 8.22424 12.8573 9.99944 12.8573Z"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7137 19.2857C15.1729 18.2364 14.3536 17.3562 13.3456 16.7418C12.3377 16.1273 11.1799 15.8022 9.99944 15.8022C8.81894 15.8022 7.66123 16.1273 6.65326 16.7418C5.64528 17.3562 4.82597 18.2364 4.28516 19.2857"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1432 15.0857C18.157 13.7634 18.7805 12.1838 18.9433 10.5256C19.1061 8.86743 18.8017 7.19675 18.0645 5.70255C17.3272 4.20835 16.1866 2.95025 14.7716 2.07054C13.3566 1.19083 11.7237 0.724609 10.0575 0.724609C8.39131 0.724609 6.75837 1.19083 5.34336 2.07054C3.92835 2.95025 2.78772 4.20835 2.05049 5.70255C1.31326 7.19675 1.00883 8.86743 1.17164 10.5256C1.33445 12.1838 1.958 13.7634 2.97177 15.0857"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
