import {
  GET_MAM_PROFILES_FAIL,
  GET_MAM_PROFILES_START,
  GET_MAM_PROFILES_SUCCESS,
  GET_MAM_PROFILE_FAIL,
  GET_MAM_PROFILE_START,
  GET_MAM_PROFILE_SUCCESS,
  SET_SELECTED_MAM_PROFILE,
} from "./actionTypes";

export const setSelectedMamProfile = (data) => {
  return {
    type: SET_SELECTED_MAM_PROFILE,
    payload: data,
  };
};

export const getMamProfilesInitAction = (params) => {
  return {
    type: GET_MAM_PROFILES_START,
    payload: params,
  };
};

export const getMamProfilesSuccessAction = (data) => {
  return {
    type: GET_MAM_PROFILES_SUCCESS,
    payload: data,
  };
};

export const getMamProfilesFailAction = (params) => {
  return {
    type: GET_MAM_PROFILES_FAIL,
    payload: params,
  };
};

export const getMamProfileInitAction = (params) => {
  return {
    type: GET_MAM_PROFILE_START,
    payload: params,
  };
};

export const getMamProfileSuccessAction = (params) => {
  return {
    type: GET_MAM_PROFILE_SUCCESS,
    payload: params,
  };
};

export const getMamProfileFailAction = (params) => {
  return {
    type: GET_MAM_PROFILE_FAIL,
    payload: params,
  };
};
