/* eslint-disable indent */
import { AvForm } from "availity-reactstrap-validation";
import PendingRequestBadge from "components/Forex/Common/PendingRequestBadge";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { postUnlinkReqInit } from "store/actions";

const { SingleValue, Option } = components;

const AccountSelect = (props) => {
  const { t } = useTranslation();

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div className="color-primary font-weight-bold border-0" style={{ fontSize: "12px" }}></div>
      <div style={{ fontWeight: "bold" }}>{props.data.label}</div>
    </SingleValue>
  );

  const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;

  const customStyles = {
    // Custom styles for the select component
    // ...
  };

  return (
    <>
      <div style={{ backgroundColor: "transparent" }}>
        <Select
          {...props}
          className="basic-single color-primary shadow border-0"
          placeholder={t("Select Profile")}
          styles={customStyles}
          onInputChange={(input) => {
            if (input !== "") {
              if (!/^-?\d*\.?\d*$/.test(input)) {
                return "";
              }
            }
            return input;
          }}
          components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
          }}
          options={props.options}
        />
      </div>
    </>
  );
};

const UnlinkInvestorModal = ({ isOpen, toggle, profiles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [investorOptions, setInvestorOptions] = useState([]);
  // const [typeOptions, setTypeOptions] = useState([
  //   {
  //     label: "New",
  //     value: "New",
  //   },
  //   {
  //     label: "Existing",
  //     value: "Existing",
  //   },
  // ]);

  const handleProfileChange = (selectedOption) => {
    setSelectedProfile(selectedOption);
    const selectedProfileId = selectedOption.value;
    const filteredInvestorOptions = profiles
      .find((profile) => profile._id === selectedProfileId)
      .slaves.map((slave) => ({
        label: slave.login,
        value: slave.tradingAccountId,
      }));
    setInvestorOptions(filteredInvestorOptions);
    setSelectedInvestor(null);
  };

  const handleInvestorChange = (selectedOption) => {
    setSelectedInvestor(selectedOption);
  };

  const handleSubmit = () => {
    dispatch(
      postUnlinkReqInit({
        profileId: selectedProfile.value,
        investorId: selectedInvestor.value,
      })
    );
  };
  // eslint-disable-next-line no-console
  console.log("investor options:", investorOptions);
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Unlink Investor From SP")}
      </ModalHeader>
      <ModalBody>
        <AvForm>
          <Label className="form-label mb-2">{t("Profile")}</Label>
          <AccountSelect
            options={
              profiles && profiles.length
                ? profiles.map((profile) => ({
                    label: profile.masterTradingAccountId.login,
                    value: profile._id,
                  }))
                : []
            }
            value={selectedProfile}
            onChange={handleProfileChange}
          />
          {investorOptions && investorOptions?.length > 0 ? (
            <>
              <Label className="form-label my-2">{t("Account")}</Label>
              <AccountSelect
                options={investorOptions}
                value={selectedInvestor}
                onChange={handleInvestorChange}
              />
            </>
          ) : (
            selectedProfile &&
            investorOptions &&
            investorOptions.length === 0 && (
              <div className="my-4">
                <PendingRequestBadge text="No investor account found" />
              </div>
            )
          )}

          {/* <Label className="form-label my-2">{t("Type")}</Label>
          <AccountSelect
            options={typeOptions}
            value={setselectedType}
            onChange={(v) => {
              setSetselectedType(v);
            }}
          /> */}
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-2 mx-auto">
          <Button onClick={toggle}>{t("Cancel")}</Button>
          <Button
            onClick={handleSubmit}
            disabled={!selectedProfile || !selectedInvestor}
            className="btn btn-success"
            type="submit"
          >
            {t("Unlink")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UnlinkInvestorModal;
