import { getAvailableInvestorAccountsAPI } from "apis/forex/accounts";
import { postLinkRequestAPI } from "apis/forex/requests";
import { AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { startCase } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import ProfitGraph from "./ProfitGraph";

export default function LinkModal(props) {
  const { isOpen, toggle, profileId, selectedProfile } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [investorId, setInvestorId] = React.useState(null);
  const [availableAccounts, setAvailableAccounts] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && selectedProfile) {
      setLoading(true);
      getAvailableInvestorAccountsAPI({
        page: 1,
        limit: 1000,
      })
        .then((res) => {
          if (res.result) {
            const newAvailableAccounts = res.result?.map((item) => ({
              label: `${item.login} | ${item.platform} | ${item.type} | ${item.currency} ${item.Equity ?? 0}`,
              value: item,
            }));
            setAvailableAccounts(newAvailableAccounts);
          } else {
            setAvailableAccounts([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setAvailableAccounts([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen, profileId]);

  const handleSubmit = () => {
    setLoading(true);
    const account = availableAccounts.find((item) => item.value === investorId)?.value;
    if (parseFloat(account.Equity) < parseFloat(selectedProfile?.feeSetting?.managementFee)) {
      dispatch(showErrorNotification("Account equity is less than management fee"));
      setLoading(false);
      return;
    }
    postLinkRequestAPI({
      params: {
        profileId,
        investorId: account._id,
      },
    })
      .then((res) => {
        if (!res.isSuccess) {
          throw new Error(res.message);
        }
        dispatch(showSuccessNotification("Request sent successfully!"));
        toggle();
      })
      .catch((err) => {
        console.log(err);
        dispatch(showErrorNotification(err?.message || "Something went wrong!"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProfileName = (profileType) => {
    switch (profileType) {
      // case 1 : return " MAM";
      case 2:
        return " PAM";
      case 9:
        return " Copy Trading/Social Trading";
      default:
        return "";
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="xl">
      <ModalHeader toggle={toggle} className="color-primary">
        {t("Link Account")}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <Row className="my-2">
            <Col md={6}>
              <h4 className="text-center">{t("Details")}</h4>
              {/* <div className="d-flex justify-content-between">
                <h5>{t("Name")}</h5>
                <h5 className="color-primary">{selectedProfile?.customerId?.firstName} {selectedProfile?.customerId?.lastName}</h5>
              </div> */}
              <div className="d-flex justify-content-between">
                <h5>{t("Investors")}</h5>
                <h5 className="color-primary">{selectedProfile?.slaves?.length || 0}</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>{t("Master Login")}</h5>
                <h5 className="color-primary">{selectedProfile?.username ?? "-"}</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>{t("Equity")}</h5>
                <h5 className="color-primary">
                  {selectedProfile?.masterTradingAccountId?.Equity ?? 0}
                </h5>
              </div>
              <div className="d-flex justify-content-between my-1">
                <h5>{t("Profile Type")}: </h5>
                <h5 className="color-primary">{getProfileName(selectedProfile.profileType)}</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>{t("Total Profit")}</h5>
                <h5 className="color-primary">
                  {selectedProfile?.masterTradingAccountId?.totalProfit ?? 0}
                </h5>
              </div>
              {/* <div className="d-flex justify-content-between">
                <h5>{t("Total Volume Opened")}</h5>
                <h5 className="color-primary">{(parseFloat(selectedProfile?.masterTradingAccountId?.lotsOpened) / 1000)?.toFixed(3)}</h5>
              </div> */}
              <div className="d-flex justify-content-between">
                <h5>{t("Total Volume Closed")}</h5>
                <h5 className="color-primary">
                  {(
                    parseFloat(selectedProfile?.masterTradingAccountId?.lotsClosed ?? 0) / 10000
                  )?.toFixed(3)}
                </h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>{t("Win Rate")}</h5>
                <h5 className="color-primary">
                  {selectedProfile?.masterTradingAccountId?.totalTradesWon
                    ? (
                        parseFloat(
                          selectedProfile?.masterTradingAccountId?.totalTradesWon /
                            selectedProfile?.masterTradingAccountId?.totalTrades ?? 0
                        ) * 100
                      )?.toFixed(3)
                    : 0}
                </h5>
              </div>
            </Col>
            <Col md={6}>
              <ProfitGraph accountId={selectedProfile?.masterTradingAccountId?._id} />
            </Col>
          </Row>
          <Row className="my-2">
            <h4 className="text-center">{t("Fees Structure")}</h4>
            {selectedProfile?.feeSetting && (
              <>
                {Object.keys(selectedProfile?.feeSetting).map((key, index) => (
                  <div key={index} className="d-flex justify-content-between my-1">
                    <h5>{startCase(key)}</h5>
                    <h5 className="color-primary">{selectedProfile?.feeSetting?.[key] || "-"}</h5>
                  </div>
                ))}
              </>
            )}
          </Row>
          <Row className="my-4">
            <AvFieldSelecvt
              label={t("Select Investor")}
              name="investor"
              // options={availableAccounts?.filter((item) => item?.type === "MAM_MASTER" ? selectedProfile?.profileType === 9 : true) || []}
              options={availableAccounts}
              required
              onChange={(e) => {
                setInvestorId(e);
              }}
            />
          </Row>
          <Row className="my-3 mx-5 ">
            {loading ? (
              <div className="text-center w-100">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Button
                className="color-bg-btn btn border-0"
                disabled={loading || profileId === null || investorId === null}
              >
                {t("Link")}
              </Button>
            )}
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
