import React from "react";

export default function depositSvg(props) {
  const { fill } = props;
  return (
    <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14.3145V15.7573"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.15723V8.50008"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0011 19.2714C15.0011 19.2714 18.5725 17.5 18.5725 13.5571C18.5725 9.27139 16.4297 6.41424 12.144 4.27139L13.8297 2.09996C13.9153 1.95749 13.9618 1.79491 13.9643 1.6287C13.9669 1.46249 13.9255 1.29856 13.8442 1.15353C13.763 1.00849 13.6449 0.887506 13.5018 0.802834C13.3588 0.718163 13.1959 0.672815 13.0297 0.671387H6.97254C6.80632 0.672815 6.64343 0.718163 6.50038 0.802834C6.35734 0.887506 6.23922 1.00849 6.158 1.15353C6.07678 1.29856 6.03534 1.46249 6.0379 1.6287C6.04045 1.79491 6.08691 1.95749 6.17254 2.09996L7.85826 4.28567C3.57254 6.44282 1.42969 9.29996 1.42969 13.5857C1.42969 17.5 5.00112 19.2714 10.0011 19.2714Z"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57221 13.6573C8.74652 13.8642 8.96591 14.0284 9.21354 14.1374C9.46117 14.2463 9.73047 14.2971 10.0008 14.2859C10.4139 14.314 10.8219 14.1809 11.1389 13.9145C11.456 13.6481 11.6574 13.2691 11.7008 12.8573C11.6574 12.4455 11.456 12.0665 11.1389 11.8001C10.8219 11.5337 10.4139 11.4005 10.0008 11.4287C9.58763 11.4569 9.17965 11.3238 8.86262 11.0573C8.54558 10.7909 8.34419 10.412 8.30078 10.0001C8.3407 9.58687 8.54112 9.20574 8.85899 8.93862C9.17686 8.67151 9.58681 8.53972 10.0008 8.57157C10.2663 8.55532 10.5322 8.59815 10.7792 8.69695C11.0262 8.79576 11.2483 8.9481 11.4294 9.143"
        stroke={fill}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
