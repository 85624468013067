const LOG_TYPES = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  CONVERT_CUSTOMER: "CONVERT_CUSTOMER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  CONVERT: "CONVERT",
  ORDER: "ORDER",
  WALLET: "WALLET",
  // docs
  UPLOAD_DOCS: "UPLOAD_DOCS",
  OVERWRITE_DOCS: "OVERWRITE_DOCS",
  CHANGE_DOC_STATUS: "CHANGE_DOC_STATUS",
  // bank accs
  ADD_BANK_ACCOUNT: "ADD_BANK_ACCOUNT",
  EDIT_BANK_ACCOUNT: "EDIT_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  PROFILE_COMPLETED: "PROFILE_COMPLETED",
  // 2fa
  ENABLE_2FA: "ENABLE_2FA",
  DISABLE_2FA: "DISABLE_2FA",
  // requests
  IB_REQUEST: "IB_REQUEST",
  UPDATE_IB_REQUEST: "UPDATE_IB_REQUEST",
  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  LEVERAGE_REQUEST: "LEVERAGE_REQUEST",
  UPDATE_LEVERAGE_REQUEST: "UPDATE_LEVERAGE_REQUEST",
  // create trading accounts
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  // transactions
  FX_DEPOSIT: "FX_DEPOSIT",
  FX_DEPOSIT_UPDATE: "FX_DEPOSIT_UPDATE",
  FX_WITHDRAW: "FX_WITHDRAW",
  FX_WITHDRAW_UPDATE: "FX_WITHDRAW_UPDATE",
  FX_INTERNAL_TRANSFER: "FX_INTERNAL_TRANSFER",
  FX_INTERNAL_TRANSFER_UPDATE: "FX_INTERNAL_TRANSFER_UPDATE",
  FX_CREDIT_UPDATE: "FX_CREDIT_UPDATE",
  FX_DEPOSIT_AUTO: "FX_DEPOSIT_AUTO",
  FX_WITHDRAW_AUTO: "FX_WITHDRAW_AUTO",
  FX_INTERNAL_TRANSFER_AUTO: "FX_INTERNAL_TRANSFER_AUTO",
  // Copy Trading
  PROFILE_REQUESTED: "PROFILE_REQUESTED",
  PROFILE_APPROVED: "PROFILE_APPROVED",
  PROFILE_REJECTED: "PROFILE_REJECTED",

  PROFILE_LINK_REQUESTED: "PROFILE_LINK_REQUESTED",
  PROFILE_LINK_APPROVED: "PROFILE_LINK_APPROVED",
  PROFILE_LINK_REJECTED: "PROFILE_LINK_REJECTED",

  PROFILE_UNLINK_REQUESTED: "PROFILE_UNLINK_REQUESTED",
  PROFILE_UNLINK_APPROVED: "PROFILE_UNLINK_APPROVED",
  PROFILE_UNLINK_REJECTED: "PROFILE_UNLINK_REJECTED",

  INVESTOR_REQUESTED: "INVESTOR_REQUESTED",
  INVESTOR_APPROVED: "INVESTOR_APPROVED",
  INVESTOR_REJECTED: "INVESTOR_REJECTED",
};

const PORTALS = {
  CRYPTO: "CRYPTO",
  FOREX: "FOREX",
  GOLD: "GOLD",
  MM: "MM",
};

const CUSTOMER_TYPES = {
  INDIVIDUAL: "INDIVIDUAL",
  CORPORATE: "CORPORATE",
};

const CUSTOMER_SUB_TYPES = {
  LIVE: "LIVE",
  DEMO: "DEMO",
  IB: "IB",
  INVESTOR: "INVESTOR",
  SP: "SP",
};

const CUSTOMER_SUB_PORTALS = CUSTOMER_SUB_TYPES;

const LOG_LEVELS = {
  0: "DEBUG",
  1: "INFO",
  2: "WARN",
  3: "ERROR",
};

const TRIGGERED_BY = {
  0: "Client",
  1: "User",
};

const CLIENT_OR_IB = {
  CLIENT: "CLIENT",
  IB: "IB",
};

const IS_IOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

export {
  LOG_LEVELS,
  LOG_TYPES,
  TRIGGERED_BY,
  PORTALS,
  CLIENT_OR_IB,
  CUSTOMER_TYPES,
  CUSTOMER_SUB_TYPES,
  CUSTOMER_SUB_PORTALS,
  IS_IOS,
};
